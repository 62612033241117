<template>
  <CRow>
    <CCol sm="8">
      <CCard>
        <CCardHeader>
          <b>Service</b>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <dt class="col-sm-2">ID</dt>
            <dd class="col-sm-10">{{ service.id }}</dd>

            <dt class="col-sm-2">Name</dt>
            <dd class="col-sm-10">{{ service.name }}</dd>

            <dt class="col-sm-2">Description</dt>
            <dd class="col-sm-10">{{ service.description }}</dd>

            <dt class="col-sm-2">Category</dt>
            <dd class="col-sm-10">{{ service.category.name }}</dd>

            <dt class="col-sm-2">Subcategory</dt>
            <dd class="col-sm-10">{{ service.subcategory.name }}</dd>

            <dt class="col-sm-2">PriceType</dt>
            <dd class="col-sm-10">{{ service.price_type }}</dd>

            <dt class="col-sm-2">ServiceType</dt>
            <dd class="col-sm-10">{{ service.service_type }}</dd>

            <dt class="col-sm-2">NumOfTimes</dt>
            <dd class="col-sm-10">{{ service.num_of_times }}</dd>

            <dt class="col-sm-2">Period</dt>
            <dd class="col-sm-10">{{ service.period }}</dd>

            <dt class="col-sm-2">NumOfUnits</dt>
            <dd class="col-sm-10">{{ service.num_of_units }}</dd>

            <dt class="col-sm-2">UnitType</dt>
            <dd class="col-sm-10">{{ service.unit_type }}</dd>

            <dt class="col-sm-2">Price</dt>
            <dd class="col-sm-10">{{ service.base_price }}</dd>

            <dt class="col-sm-2">CreatedDate</dt>
            <dd class="col-sm-10">{{ service.created_at }}</dd>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACTION_FETCH_SERVICE } from '@/store/actions.type';

export default {
  name: 'Service',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('services');
    });
  },
  data() {
    return {};
  },
  mounted() {
    const id = this.$route.params.id;
    this.$store.dispatch(ACTION_FETCH_SERVICE, id);
  },
  computed: {
    ...mapGetters(['service']),
  },
  methods: {
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/services' });
    },
  },
};
</script>
